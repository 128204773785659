@import "./_variables";

* {
    box-sizing: border-box;
    font-size: $font-size--default;
    font-family: $font-family--default;
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
}

a {
    color: $color--secondary;
    text-decoration: none;

    &:hover,
    &:active {
        text-decoration: underline;
    }

    &:hover,
    &:active,
    &:visited {
        color: $color--secondary;
    }
}

h1 {
    font-size: $font-size--h1;
}

h2 {
    font-size: $font-size--h2;
}

h3 {
    font-size: $font-size--h3;
}

h4 {
    font-size: $font-size--h4;
}

h5 {
    font-size: $font-size--h5;
}

h6 {
    font-size: $font-size--h6;
}

.title {
    color: #323232;
    font-size: 24px;
    font-weight: 600;
}

input[type="text"], textarea[type="text"], input[type="number"] {
    padding: $spacing--smaller $spacing--small;
    font-size: $font-size--default;
    border: 1px solid #bcbcbc;
    height: 48px;
    padding: 0.5rem 1rem;
    display: block;
    width: 100%;
    font-size: 14px;
}

textarea[type="text"] {
    height: auto !important;
}

ba-app-root {
    height: 100%;
}

.primary-container {
    max-width: 1600px;
    margin: 0 auto;
}

.close-dialog-button {
    position: absolute !important;
    right: 1rem;
    top: 1rem;
    border: none;
    background-color: transparent;
    padding: 0;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #747474;
}

.input--search {
    position: relative;

    .pi-search {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        color: #747474;
        cursor: pointer;
    }

    input {
        height: 40px;
        width: 290px;
        padding-left: 44px;
    }
}
