@import './variables';

input,
button {
  border-radius: 0 !important;
}

input::placeholder {
  color: #bcbcbc;
}

input:enabled:focus {
  border-color: #2196f3;
  outline: none !important;
}

input:disabled,
.p-dropdown.p-disabled {
  background-color: #eaeaea;
  border-color: #bcbcbc !important;
  opacity: 0.8;
}

.btn {
  color: white !important;
}

.btn.btn-link {
  color: #323232 !important;
  text-decoration: none !important;
}

.btn.btn-secondary {
  color: $color--secondary !important;
  background-color: white;
  border-color: $color--secondary;

  &:hover {
    background-color: #eaeaea;
  }

  &:disabled {
    color: #bcbcbc !important;
    background-color: #eaeaea !important;
    border-color: #eaeaea !important;
  }
}

.btn.btn-default {
  color: #323232 !important;
}

// material table style
.table-common {
  th {
    text-align: left;
    font-size: 12px;
    font-weight: 600;
    color: #323232;

    .mat-sort-header-content {
      text-align: left;
      font-size: 12px;
    }
  }

  thead tr {
    background-color: #f4f4f4;
  }

  td {
    color: #323232;
    font-size: 14px;
  }

  // Override sort icon
  .mat-sort-header-arrow {
    .mat-sort-header-stem {
      display: none;
    }

    .mat-sort-header-indicator {
      opacity: 1;
      color: black;
      font-weight: bold;

      top: 50%;
      transform: translateY(-50%);
      margin-top: -2px;

      // Hide default arrow as its composed of left, right and middle
      .mat-sort-header-pointer-left,
      .mat-sort-header-pointer-right,
      .mat-sort-header-pointer-middle {
        display: none;
      }
    }
  }

  [aria-sort='ascending'] {
    .mat-sort-header-arrow {
      .mat-sort-header-indicator {
        &::before {
          content: '';
          background-image: url('/assets/images/down-arrow.png');
          transform: rotateZ(180deg);
          top: -5px;
          line-height: 0;
          position: absolute;
          display: inline-block;
          width: 14px;
          height: 14px;
          background-size: cover;
        }
      }
    }
  }

  // My custom descending arrow
  [aria-sort='descending'] {
    .mat-sort-header-arrow {
      .mat-sort-header-indicator {
        &::before {
          content: '';
          background-image: url('/assets/images/down-arrow.png');
          top: -15px;
          line-height: 0;
          position: absolute;
          width: 14px;
          height: 14px;
          background-size: cover;
        }
      }
    }
  }
}

// material select style
.mat-select-trigger {
  border: 1px solid #bcbcbc;
  height: 48px;
  padding: 0.5rem 0.75rem 0.5rem 1rem;
  box-sizing: border-box;

  .mat-select-value {
    line-height: 30px;
    font-size: 14px;
  }

  .mat-select-value-text * {
    font-size: 14px;
  }
}

.mat-select-panel-wrap {
  .mat-option-text {
    font-size: 14px;
  }
}

// material snack-bar
.mat-simple-snack-bar-content {
  font-size: 14px;
}

.mat-button-wrapper {
  font-size: 14px;
  font-weight: bold;
}

// primary input
.p-inputtext:enabled:focus {
  box-shadow: none !important;
  border-color: #407ec9 !important;
}

.p-inputtext {
  height: 40px !important;
}

p-calendar {
  border: none;
}

.p-dropdown {
  border: 1px solid #bcbcbc !important;

  &:not(.p-disabled).p-focus {
    box-shadow: none !important;
  }

  &.p-disabled {
    cursor: not-allowed !important;
  }
}

.p-multiselect {
  border: 1px solid #bcbcbc !important;
  border-radius: 0 !important;

  &:not(.p-disabled).p-focus {
    box-shadow: none !important;
  }

  &.p-disabled {
    cursor: not-allowed !important;
  }
}

// prime calendar
p-calendar {
  .p-component:disabled {
    opacity: 1 !important;
    background-color: #eaeaea;
    color: #747474;
  }
}

.mat-dialog-container {
  position: relative;
}

// prime switch
p-inputswitch {
  height: 22px;

  .p-inputswitch {
    height: 22px !important;
    width: 44px !important;
  }

  .p-inputswitch-slider:before {
    height: 18px !important;
    width: 18px !important;
    margin-top: -9px !important;
    margin-left: -1px !important;
  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(20px) !important;
  }
}

/* Datepicker */
.p-datepicker table td {
  padding: 0 !important;
  width: 40px !important;
  height: 40px !important;
}

.p-datepicker table tr {
  background-color: #ffffff !important;
}

.p-datepicker table thead tr th span {
  color: #19181a !important;
  font-size: 14px !important;
}

.p-datepicker span.p-ripple.p-element {
  border-radius: unset;
  margin: 0;
  color: #323232;
  font-weight: 600;
  font-size: 14px;
}

.p-datepicker {
  width: 345px !important;
  padding: 20px !important;
}

.p-datepicker .p-monthpicker {
  display: flex;
  flex-wrap: wrap;
}

.p-datepicker .p-yearpicker {
  display: flex;
  flex-wrap: wrap;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  padding: 5px 15px !important;
  color: #407ec9 !important;
  background-color: #f4f4f4;
  border-radius: 8px !important;
}

.p-datepicker table td.p-datepicker-today > span {
  background: #407ec9 !important;
  color: #ffffff !important;

  &:hover {
    color: #19181a !important;
  }
}

.calendar-wrap {
  position: relative;
}

.calendar-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}
