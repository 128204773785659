.p-dropdown {
    border-radius: 0px !important;

    &.large-input {
        .p-inputtext {
            height: 48px !important;
            display: flex;
            align-items: center;
        }
    }
}