@import "_variables";

.mat-tab-group {
    .mat-tab-label {
        height: 24px !important;
        min-width: auto !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-right: 20px;

        &.mat-tab-label-active {
            .mat-tab-label-content {
                color: $color--primary !important;
            }
        }
    }

    .mat-tab-body-content {
        overflow-x: hidden !important;
    }
}