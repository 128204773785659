@import "./_variables";

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

button.overlay-spinner {
    position: relative;
    pointer-events: none;
}

.overlay-spinner {
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(255 255 255 / 50%);
    }

    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
        border-radius: 50%;
        border: 2px solid transparent;
        border-top-color: currentColor;
        animation: spinner 0.8s linear infinite;
    }
}

.button--primary {
    background-color: #407ec9;
    border: 1px solid #407ec9;
    text-align: center;
    color: #fff;
    font-size: 14px;
    border: none;
    transition: all 0.3s ease-in-out;
    padding: 10px 16px;

    &:hover {
        background-color: #266fc7;
    }

    &:disabled {
        background-color: rgba(#407ec9, 0.7);
        cursor: not-allowed;
    }
}

.button--outline {
    @extend .button--primary;

    background-color: #fff;
    color: #747474 !important;
    border: 1px solid #bcbcbc;

    &:hover:not(:disabled) {
        color: #407ec9 !important;
        border-color: #407ec9;
        background-color: #fff;
    }

    &:disabled {
        background-color: #eaeaea;
        border-color: #eaeaea;
        color: #bcbcbc !important;
        opacity: 0.65;
    }
}

.button--danger {
    @extend .button--primary;
    background-color: #d82c0d;

    &:hover {
        background-color: #f03c1c;
    }

    &:disabled {
        background-color: rgba(#d82c0d, 0.7);
    }
}