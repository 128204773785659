.mat-dialog-container {
    overflow: visible !important;
    border-radius: 0 !important;
    position: relative;
}

.close-button {
    position: absolute !important;
    top: 10px;
    right: 10px;
}
